@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @keyframes modal-slide-up {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 0;
    }
  }

  @keyframes modal-slide-down {
    0% {
      bottom: 0;
    }

    100% {
      bottom: -100%;
    }
  }
}
