.modal-temporary-fix {
  /* Fix glitching error while closing Modal appeared with React 18 */
  /* https://github.com/pradel/react-responsive-modal/issues/495 */
  animation-fill-mode: forwards !important;
}

/* Prevent Tailwind to add undesired box-shadow when an input get focused */
/* Useful for SelectInput component with "isMulti=true" in particular */
input:focus {
  box-shadow: none !important;
}
